import { ActionMap } from "@models/IContext";
import { Setting, BaseQuery } from "@models/ISchema";

export type BookingClassroomOrder = {
  field: string;
  direction: string;
};

export type BookingClassroomOrderPayload = BookingClassroomOrder[];

export type BaseTypes = {
  settings?: Setting[];
  isMouseflowEnabled?: boolean;
  isGroupManagerFilterEnabled?: boolean;
  bookingClassroomOrder?: BookingClassroomOrderPayload;
  base?: BaseQuery;
};

export enum Types {
  SetSettings = "SET_SETTINGS",
  SetMouseflowEnabled = "SET_MOUSEFLOW_ENABLED",
  setGroupManagerFilterEnabled = "SET_GROUP_MANAGER_FILTER_ENABLED",
  setBookingClassroomOrder = "SET_BOOKING_CLASSROOM_ORDER",
  SetBase = "SET_BASE",
}

type BasePayload = {
  [Types.SetSettings]: Setting[];
  [Types.SetMouseflowEnabled]: boolean;
  [Types.setGroupManagerFilterEnabled]: boolean;
  [Types.setBookingClassroomOrder]: BookingClassroomOrderPayload;
  [Types.SetBase]: BaseQuery;
};

export type BaseActions = ActionMap<BasePayload>[keyof ActionMap<BasePayload>];

export const baseReducer = (state: BaseTypes, action: BaseActions) => {
  switch (action.type) {
    case Types.SetSettings:
      return {
        ...state,
        settings: action.payload,
      };
    case Types.SetMouseflowEnabled:
      return {
        ...state,
        isMouseflowEnabled: action.payload,
      };
    case Types.setGroupManagerFilterEnabled:
      return {
        ...state,
        isGroupManagerFilterEnabled: action.payload,
      };
    case Types.setBookingClassroomOrder:
      return {
        ...state,
        bookingClassroomOrder: action.payload,
      };
    case Types.SetBase:
      return {
        ...state,
        base: action.payload,
      };
    default:
      return state;
  }
};
