import React, { createContext, useReducer, Dispatch } from "react";
import { baseReducer, BaseActions, BaseTypes } from "./base.reducer";

export type InitialStateType = {
  base: BaseTypes;
};

export const defaultBookingClassroomOrder = [
  {
    field: "capacity",
    direction: "ASC",
  },
];

export const initialState: InitialStateType = {
  base: {
    settings: null,
    isMouseflowEnabled: false,
    isGroupManagerFilterEnabled: true,
    bookingClassroomOrder: defaultBookingClassroomOrder,
    base: null,
  },
};

const ContextEDH = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<BaseActions | void>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = ({ base }: InitialStateType, action: any) => ({
  base: baseReducer(base, action),
});

const ContextEDHProvider: React.FC<{ children?: React.ReactNode | undefined }> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <ContextEDH.Provider value={{ state, dispatch }}>{children}</ContextEDH.Provider>;
};

export { ContextEDHProvider, ContextEDH };
